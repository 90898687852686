import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Columns, Box } from "react-bulma-components"
import 'bulma/css/bulma.min.css';
import Layout from "../components/layout"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"


// markup
const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql `
    query IndexQuery {
      allSitePage {
        nodes {
          path
          pageContext
        }
      }
    }
  `)

  const staticPages = data.allSitePage.nodes.filter((p) => p.pageContext.type === "page");
  const recurringEvents = data.allSitePage.nodes.filter((p) => p.pageContext.type === "recurring");
  const yesterday = Date.now() - (24*60*60*1000);
  const upcomingEvents = data.allSitePage.nodes.filter((p) => p.pageContext.StartsAt && new Date(p.pageContext.StartsAt) >= yesterday);
  const pastEvents = data.allSitePage.nodes.filter((p) => p.pageContext.StartsAt && new Date(p.pageContext.StartsAt) < yesterday);

  const PreviewImage = ({ page }) => {
    if ( page.pageContext.MainImage ) {
      return <GatsbyImage
        className="preview-image"
        image={page.pageContext.MainImage?.localFile.childImageSharp.gatsbyImageData}
      />
    } else {
      return <StaticImage
        className="preview-image"
        src="../images/sample.png"
        objectFit="cover"
      />
    }
  }

  const Tile = ({ page, mobileCol, isOver }) => {
    const startDate = new Date(page.pageContext.StartsAt)
    const displayDate = startDate.toLocaleString('de-DE', {weekday: 'short', day: 'numeric', month: 'short', year: '2-digit'})

    return <Columns.Column mobile={{ size: mobileCol }} tablet={{ size: 6 }} desktop={{ size: 4 }} widescreen={{ size: 3 }} >
      <Link to={page.path}>
        <Box className={`${page.pageContext.type} ${isOver && 'is-over'}`}>
          {<PreviewImage page={page}/>}
          <div className="text-layer angled">
            <h2 className="title">{page.pageContext.Title}</h2>
            {page.pageContext.StartsAt && !page.pageContext.HideDate && <><span className="starts-at">{displayDate}</span></>}
            {page.pageContext.RecurringDate && <><span className="starts-at">{page.pageContext.RecurringDate}</span></>}
          </div>
        </Box>
      </Link>
    </Columns.Column>
  }

  const Brand = () => (
    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 6 }} desktop={{ size: 4 }} widescreen={{ size: 3 }} >
      <Box className="has-text-centered page brand-box angled">
        <h1 className="title">
          PLATTEN <br/>
          KOSMOS <br/>
          <span>.berlin</span>
        </h1>
      </Box>
    </Columns.Column>
  )

  const Archive = () => (
    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 6 }} desktop={{ size: 4 }} widescreen={{ size: 3 }} >
      <Box>
        <div className="angled archive has-text-centered">
          <h2 className="title">
            ARCHIV ↘ <br/>
          </h2>
        </div>
      </Box>
    </Columns.Column>
  )

  return (
    <Layout location={location}>
      <Seo title="PLATTENKOSMOS.berlin" />

      <Columns className="is-variable is-1-mobile is-mobile">
        <Brand />
        {staticPages.map((page, index) => <Tile page={page} index={index} mobileCol={6} />)}
        {recurringEvents.map((page, index) => <Tile page={page} index={index} mobileCol={12} />)}
        {upcomingEvents.reverse().map((page, index) => <Tile page={page} index={index} mobileCol={12} />)}
        <Archive />
        {pastEvents.map((page, index) => <Tile page={page} index={index} mobileCol={12} isOver />)}
      </Columns>
    </Layout>
  )
}

export default IndexPage
